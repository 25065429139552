<template>
  <div>
    <div class="ann">
      <div class="ann-img" v-for="(item, index) in content" :key="index">
        <img v-if="item.type == 1" :src="item.url" alt="" />
        <iframe
          v-if="item.type == 2"
          style="width: 100%; height: 5rem"
          :src="item.url"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { getnoticeinfo } from '@/service/home';
import { useStore } from 'vuex';

export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      id: null,
      detail: {},
      content: []
    });

    onMounted(async () => {
      state.id = route.query.id;
      const { data } = await getnoticeinfo({ id: state.id });
      state.detail = data;
      state.content = JSON.parse(data.content);
      console.log(state.content);
      await store.dispatch('getnoticereadstatus');
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped>
.ann {
  // padding-bottom: 76px;
  .ann-img {
    width: 100vw;
    font-size: 0px;
    img {
      width: 100%;
    }
  }
}
</style>
